@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';

.custom-arrow {
  @include button;
  position: absolute;
  bottom: -4.3rem;
  width: 5.2rem;
  height: 3.2rem;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30rem;
  border: 2px solid $color-bg;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      color: $color-light-xl;
      background-color: $color-bg;
    }
  }

  &_type {
    &_prev {
      left: calc(50% - 5.6rem);
      background-image: url(../../images/icons/arrow-left-purple.svg);

      @include media-hover {
        &:hover {
          background-image: url(../../images/icons/arrow-left.svg);
        }
      }
    }

    &_next {
      left: calc(50% + 0.4rem);
      background-image: url(../../images/icons/arrow-right-purple.svg);

      @include media-hover {
        &:hover {
          background-image: url(../../images/icons/arrow-right.svg);
        }
      }
    }
  }
}
