@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/placeholders.scss';

.header {
  padding: 1.3rem 0;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: $color-bg;

  @include media-medium {
    padding: 1.3rem 0;
  }

  @include media-small {
    padding: 1.05rem 0;
  }
}

.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header__container-right {
  width: 69%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  @include media-medium {
    justify-content: flex-end;
    align-items: center;
    width: auto;
    gap: 1.4rem;
  }

  @include media-small {
    gap: 1rem;
  }
}

.header__location {
  @include text($color-light-xl);
  @include location;
  padding-top: 0.3rem;

  @include media-medium {
    padding-top: 0;
  }

  @include media-tablet {
    display: none;
  }
}
