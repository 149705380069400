@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.search-form {
  width: 100%;
  justify-self: end;
  @include media-medium {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  @include media-small {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  @include media-mobile {
    &_hidden {
      visibility: hidden;
    }
  }
}

.search-icon {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 1.6rem;
  right: 17rem;
  display: block;
  background-image: url(../../../../images/icons/search.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include media-mobile {
    display: none;
  }
}

.search-clean {
  @include button;
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 1.6rem;
  right: 17rem;

  @include media-mobile {
    top: 1.1rem;
    right: 5rem;
  }
}
