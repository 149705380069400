@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/placeholders.scss';

.popup {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  padding: 7.8rem 0 70px;
  z-index: 110;
  transition: opacity 0.4s ease, visibility 0.4s ease;

  &_opened {
    visibility: visible;
    opacity: 1;
  }

  &_type_image {
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
  }

  @include media-medium {
    padding: 5rem 0 0;
  }

  @include media-small {
    padding-top: 2rem;
  }
}

.popup__container {
  margin: auto;
  padding: 3.6rem 2rem 2.4rem;
  max-width: 40.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $color-light-l;
  border-radius: 1.2rem;

  &_type_image {
    max-width: 80vw;
    margin: 0;
    padding: 0;
    width: auto;
    aspect-ratio: auto;
    background-color: transparent;
  }

  @include media-mobile {
    padding: 3.6rem 1.6rem 2.4rem;
  }
}

.popup__title {
  @include text-l;
  @extend %font-thin;
  padding-bottom: 2rem;
}

.popup__close-btn {
  @include button;
  width: 24px;
  height: 24px;
  background-image: url(../../images/icons/close.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  @extend %transition-opacity;

  &:hover {
    opacity: 0.8;
  }

  &_image {
    top: -2rem;
    right: -4rem;
    background-image: url(../../images/icons/close_img.svg);
  }

  @include media-small {
    width: 18px;
    height: 18px;
    top: 16px;
    right: 16px;

    &_image {
      top: 0;
      right: 0;
    }
  }
}
