@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.colors {
  padding: 2rem 0 10rem;
  display: flex;
  flex-direction: column;

  @include media-tablet {
    padding: 2rem 0 6rem;
  }

  @include media-mobile {
    padding: 2rem 0 4rem;
  }
}

.colors__title {
  @include text-m;
  padding-bottom: 1.4rem;
}

.colors__subtitle {
  @include text-s;
  padding-bottom: 3rem;
}

.colors__list {
  @include list;
  display: grid;
  grid-template-columns: repeat(auto-fit, 13.6rem);
  gap: 2.6rem 2.7rem;

  &_close {
    height: 17.8rem;
    overflow: hidden;
  }

  @include media-mobile {
    gap: 2.6rem 1.6rem;
  }
}

.colors__block {
  width: 100%;
  height: 17.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;
}

.colors__block-title {
  @include text-s;
}

.colors__image {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 1.25rem;
}

.colors__items {
  width: 100%;

  &_close {
    height: 27.8rem;
    overflow: hidden;

    @include media-medium {
      height: 23.5rem;
    }

    @include media-laptop {
      height: 19rem;
    }

    @include media-tablet {
      height: 16rem;
    }

    @include media-small {
      height: 11rem;
    }

    @include media-mobile {
      height: 9rem;
    }
  }

  /* Стилизация таблицы */
  table {
    width: 100%;
    border-collapse: collapse;
  }

  /* Стилизация ячеек */
  th,
  td {
    @include text-s;
    padding: 8px 0;
    text-align: center;

    @include media-medium {
      width: 18rem;
    }

    @include media-laptop {
      padding: 6px 0;
      width: 15rem;
      font-size: 12px;
    }

    @include media-small {
      padding: 4px 0;
      font-size: 10px;
    }

    @include media-mobile {
      font-size: 8px;
    }
  }

  /* Стилизация изображений */
  td img {
    display: flex;
    width: 22rem;
    aspect-ratio: 1 / 1;
    border-radius: 1.25rem;

    @include media-medium {
      width: 18rem;
    }

    @include media-laptop {
      width: 15rem;
    }

    @include media-tablet {
      width: 12rem;
    }

    @include media-small {
      width: 8rem;
    }

    @include media-mobile {
      width: 5.5rem;
    }
  }
}

.colors__show-button {
  @include button;
  @include text($color: $color-bg);
  margin-right: 5rem;
  margin-top: 3.2rem;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      transform: scale(1.03);
    }
  }

  @include media-tablet {
    margin-top: 2rem;
    margin-right: 8rem;
  }

  @include media-mobile {
    margin-top: 1rem;
    margin-right: 0;
  }
}
