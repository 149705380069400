@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/placeholders.scss';

.catalog {
  padding: 12.7rem 0 16rem;
  width: 100%;

  @include media-laptop {
    padding: 8.5rem 0 12.6rem;
  }

  @include media-small {
    padding: 6.2rem 0 0;
    background-color: $color-light-l;
    box-shadow: -4px -4px 15px 0px rgba(122, 112, 112, 0.2),
      4px 4px 15px 0px rgba(122, 112, 112, 0.2);
    position: fixed;
    top: 0;
    z-index: 98;
  }
}

.catalog__title {
  @include section-title;
  padding-bottom: 3.3rem;
  cursor: pointer;

  @include media-laptop {
    padding-bottom: 3.2rem;
  }

  @include media-small {
    display: none;
  }
}

.catalog__title_s {
  @include text-l;
  @extend %font-thin;
  display: none;

  @include media-small {
    display: flex;
  }
}

.catalog__subtitle_s {
  @include text($color-bg);
  padding: 1rem 0 1.4rem 3.6rem;
  display: none;

  @include media-small {
    display: flex;
  }
}

.catalog__container {
  // min-height: 65.2rem;
  display: grid;
  grid-template-columns: 26.2rem auto 50.9%;
  grid-template-rows: 7.8rem auto;
  align-items: start;
  column-gap: 2.2rem;
  row-gap: 1rem;
  @extend %transition-all;

  @include media-medium {
    grid-template-rows: 7.8rem 7.8rem auto;
  }

  @include media-laptop {
    grid-template-columns: 26.2rem auto 65.2%;
    grid-template-rows: 5.4rem 6.4rem auto;
    column-gap: 1rem;
    row-gap: 1.4rem;
  }

  @include media-tablet {
    grid-template-columns: 26.2rem auto 61.2%;
  }

  @include media-small {
    grid-template-columns: 1fr;
    grid-template-rows: 5.4rem 6.4rem;
  }

  @include media-mobile {
    grid-template-rows: 4.5rem 5.6rem;

    &_small {
      grid-template-rows: 0.1rem 5.6rem;
      gap: 0;
    }
  }
}

.catalog__background {
  margin: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  background: $bg-overlay;
  z-index: 98;
  transition: ease-in-out 350ms;

  &_active {
    visibility: visible;
  }
}

.catalog__filter-wrapper {
  display: flex;
  align-items: center;
}

.catalog__filter-menu {
  width: 100%;
  grid-row: 1 / 3;
  transition: ease-in-out 350ms;

  @include media-medium {
    grid-row: 1 / 4;
  }

  @include media-small {
    min-height: 100vh;
    padding: 7rem 1rem 7rem;
    max-width: 32rem;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 98;
    background-color: $color-light-xl;
  }

  &_active {
    left: 0;
    max-height: 100%;
    overflow-y: auto;
  }
}

.catalog__filter-button {
  @include button;
  width: 3.6rem;
  height: 2.4rem;
  display: none;
  background-image: url(../../images/icons/filter_list.svg);
  background-position: 0;
  background-repeat: no-repeat;
  border-radius: 0.8rem;

  &:active {
    background-color: rgba(189, 180, 180, 0.4);
  }

  @include media-small {
    display: flex;
  }

  &_back {
    @include media-laptop {
      margin-bottom: 3.2rem;
      display: flex;
    }

    @include media-small {
      margin: 0;
    }
  }
}

.catalog__filter-title {
  @include text-l;
  @extend %font-thin;
  display: none;

  @include media-small {
    display: flex;
  }
}

.catalog__button {
  @include button;
  width: 5.4rem;
  height: 5.4rem;
  display: none;
  background-image: url(../../images/icons/filter.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color-light-xl;
  border-radius: 0.8rem;

  @include media-small {
    width: 4.6rem;
    height: 4.6rem;
    display: flex;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    justify-self: end;
  }
}

.catalog__button-reset {
  @include button;
  @include text;
  padding: 1.2rem;
  width: 12rem;
  min-height: 4.6rem;
  display: none;
  justify-self: start;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  background-color: $color-light-xl;
  border-radius: 0.8rem;
  color: $color-bg-violet;
  outline: none;
  @extend %transition-all;

  @include media-small {
    display: flex;
  }
}
