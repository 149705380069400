@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';

.filter {
  padding: 2.8rem 0.8rem;
  background-color: $color-light-xl;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 2.34rem;

  @include media-small {
    padding: 1.8rem 0.8rem;
    gap: 1.3rem;
  }
}

.filter__wrapper {
  padding: 0 2rem;
}

.filter__list {
  @include list;
  padding: 1.8rem 0 0 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  @include media-small {
    gap: 1.2rem;
  }
}
