.image-popup {
  .slick-slide {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100% !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .image-popup__image {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 85vw;
      width: 100%;
      height: 100%;
    }
  }
}
