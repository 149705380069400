@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/placeholders.scss';
@import '../../../../styles/abstract/mixins.scss';

.delivery {
  width: 100%;

  .wrapper {
    padding: 10rem 4rem;

    @include media-tablet {
      padding: 3.2rem 0;
    }

    @include media-small {
      padding: 0;
    }
  }
}

.delivery__container {
  padding: 4rem 4rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  background-color: $color-bg-light;
  border-radius: 2rem;

  @include media-medium {
    padding: 3.2rem 2rem;
    flex-direction: column;
    align-items: center;
  }

  @include media-small {
    padding: 3.2rem 1.6rem;
    border-radius: 0;
  }
}

.delivery__image {
  width: 49.8rem;
  aspect-ratio: 1 / 0.7;
  object-fit: cover;
  object-position: center;
  border-radius: 1.6rem;
  filter: brightness(1.3);

  @include media-medium {
    max-width: 76.8rem;
    width: 100%;
    aspect-ratio: 1 / 0.56;
    border-radius: 1rem;
  }

  @include media-small {
    aspect-ratio: 1 / 0.7;
  }
}

.delivery__info {
  padding: 4rem;
  max-width: 75.2rem;
  width: 100%;
  background-color: $color-light-xl;
  border-radius: 2rem;

  @include media-medium {
    max-width: 76.8rem;
    padding: 2.4rem 1.6rem 1.4rem;
  }

  @include media-small {
    border-radius: 1rem;
  }
}

.delivery__title {
  @include section-title;
}

.delivery__description {
  max-width: 62.6rem;
  @include text-m;
  padding-bottom: 1.2rem;

  span {
    white-space: nowrap;
  }

  @include media-medium {
    @include text;
  }
}
