$color-light-xl: #fff;
$color-light-l: #e7e6e6;
$color-light-m: #e2e2e2;
$color-dark-m: #757575;
$color-dark-l: #545454;
$color-dark-xl: #000;
$color-red: #8c002a;
$color-lime: #cde943;
$color-lime-active: #d8ff17;
$color-bg: #660066;
$color-bg-violet: #430043;
$color-bg-dark: #2e002e;
$color-bg-middle: #b425df;
$color-bg-light: #a173df;
$bg-overlay: rgba(0, 0, 0, 0.5);

// для медиа-запросов
$content-width: 144rem;
$medium-width: 1280px;
$laptop-width: 1024px;
$tablet-width: 850px;
$small-width: 750px;
$mobile-width: 490px;

/* font */
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
