@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.sort {
  padding: 0;
  position: relative;
  width: fit-content;
  height: fit-content;

  &_active {
    border-radius: 0.8rem 0.8rem 0 0;
    box-shadow: 0 -3px 15px 4px rgba(122, 112, 112, 0.1);
  }

  @include media-medium {
    grid-column: 3 / 4;
    justify-self: end;
  }

  @include media-small {
    margin-right: 5.2rem;
    //justify-self: start;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}

.sort__wrapper {
  width: 18.5rem;
  height: 5.4rem;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  background: $color-light-xl;
  transition: all 0.4s ease-out;

  &_active {
    border-radius: 0.8rem 0.8rem 0 0;
  }

  @include media-small {
    width: 4.6rem;
    height: 4.6rem;
  }
}

.sort__arrow {
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../../../../images/icons/sort_down.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sort__button {
  @include button;
  @include text;
  padding: 1.2rem;
  width: 100%;
  justify-content: space-between;

  @include media-small {
    //padding: 0.8rem 1.6rem;
    display: none;
  }
}

.sort__button-s {
  @include button;
  padding: 1.2rem;
  width: 100%;
  display: none;
  background-position: center;
  background-size: 3.4rem;
  background-repeat: no-repeat;

  &_up {
    background-image: url(../../../../images/Catalog/arrow-up.svg);
  }

  &_down {
    background-image: url(../../../../images/Catalog/arrow-down.svg);
  }

  @include media-small {
    display: flex;
  }
}

.sort__list {
  @include list;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 0.8rem 0.8rem;
  background: white;
  box-shadow: 0 10px 10px 4px rgba(122, 112, 112, 0.1); /* получившаяся тень */
  visibility: hidden;
  opacity: 0;
  @extend %transition-all;

  &_active {
    top: 100%;
    z-index: 5;
    visibility: visible;
    opacity: 1;
  }

  @include media-small {
    width: 18.5rem;
    border-radius: 0.8rem 0 0.8rem 0.8rem;
  }
}

.sort__item {
  position: relative;
}

.sort__label {
  @include button;
  padding: 1.2rem;
  width: 100%;
  height: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    @include text;
  }

  &:hover {
    background-color: $color-light-m;
    border-radius: 0.8rem;

    span {
      @include text($color-bg, $fw-bold);
    }
  }

  @include media-small {
    height: 4.6rem;
  }
}

.sort__radio {
  width: 1px;
  height: 1px;
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
