@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/mixins.scss';

.button {
  @include button;
  @include text;
  max-width: 17.9rem;
  width: 100%;
  height: 3.8rem;
  border-radius: 1.2rem;
  border: 2px solid $color-bg;
  @extend %transition-all;

  &:active {
    color: $color-light-xl;
    background-color: $color-bg;
  }

  &_size {
    &_large {
      height: 5.6rem;
      min-width: 22.8rem;
      border-radius: 0.8rem;

      @include media-small {
        min-width: 100%;
      }
    }

    &_small {
      @include media-small {
        height: 3.2rem;
      }
    }
  }

  @include media-hover {
    &:hover {
      font-weight: $fw-bold;
      border: 0.3rem solid $color-bg;
    }
  }
}
