@import '../../../styles/abstract/constants.scss';
@import '../../../styles/abstract/mixins.scss';
@import '../../../styles/abstract/placeholders.scss';

.catalog-card {
  padding: 2.9rem;
  max-width: 23.7rem;
  display: flex;
  flex-direction: column;
  background-color: $color-light-xl;
  border-radius: 2rem;
  cursor: pointer;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      filter: drop-shadow(2px 3px 6px rgba(0, 0, 0, 0.22));
      transform: scale(1.02);
    }
  }

  @include media-medium {
    padding: 2.3rem;
    max-width: 20.5rem;
  }

  @include media-tablet {
    padding: 2.1rem 1.6rem 1.9rem;
    max-width: 13.4rem;
    border-radius: 1.2rem;

    button {
      display: none;
    }
  }
}

.catalog-card__image {
  width: 100%;
  aspect-ratio: 1 / 1.57;
  object-fit: contain;
  object-position: center;
  align-self: center;
}

.catalog-card__title-flex {
  padding-top: 1.5rem;
  min-height: 9.5rem;
  display: flex;

  @include media-medium {
    padding-top: 1.2rem;
    min-height: 7.3rem;
  }

  @include media-tablet {
    min-height: 5rem;
    padding-top: 0.7rem;
    align-items: center;
  }
}

.catalog-card__title {
  @include text-l;
  @extend %font-thin;
  overflow-wrap: break-word;
  hyphens: auto;

  @include media-medium {
    @include text-m;
  }
}
