@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';

.preloader {
  margin: auto;
  width: 5%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  border-top: 3px solid $color-bg-dark;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @include media-small {
    width: 10%;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
