@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/mixins.scss';

.form {
  width: 100%;
  min-height: 38rem;
  position: relative;
  display: grid;
  align-items: end;

  &_type {
    &_callback {
      grid-template-rows: min-content min-content min-content 1fr;
      min-height: auto;
    }

    &_measure {
      grid-template-rows: min-content min-content min-content min-content min-content 1fr;
    }

    &_search {
      width: 100%;
      min-height: auto;
      grid-template-rows: min-content;
      grid-template-columns: auto 15.1rem;
      align-items: start;
      column-gap: 0.8rem;

      .input__error {
        min-height: 0.8rem;
      }

      input {
        padding-right: 4rem;
        margin: 0;
      }

      @include media-mobile {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr min-content;
        column-gap: 0;

        input {
          padding: 1.1rem 1.6rem;
          border-radius: 8px 0px 0px 8px;
        }

        .form__submit {
          transition: none;
          width: 4.4rem;
          min-height: 4.4rem;
          background-image: url(../../images/icons/search-white.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-color: $color-bg;
          border-radius: 0px 8px 8px 0px;

          span {
            display: none;
          }
        }
      }
    }
  }
}

.form__submit {
  @include button;
  @include text;
  width: 100%;
  max-width: 49.3rem;
  min-height: 5.4rem;
  background-color: transparent;
  border-radius: 0.8rem;
  border: 0.2rem solid $color-bg;
  outline: none;
  @extend %transition-all;

  &:hover {
    font-weight: $fw-bold;
    border: 0.3rem solid $color-bg;
  }

  &:active {
    color: $color-light-xl;
    background-color: $color-bg;
  }

  &_disabled {
    color: $color-dark-l;
    @include disabled;
    background-color: transparent;
    border: 0.2rem solid rgba(102, 0, 102, 0.4);
    opacity: 0.8;
  }

  @include media-mobile {
    min-height: 4.6rem;
  }
}
