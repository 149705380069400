@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.select__label {
  cursor: pointer;
  @extend %transition-all;

  &_checked {
    p {
      @include text-m($color-dark-xl, $fw-bold);
    }
  }
}

.select__name {
  @include text-m;

  @include media-hover {
    &:hover {
      @include text-m($color-bg-violet, $fw-bold);
    }
  }
}

.select__input {
  width: 1px;
  height: 1px;
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
