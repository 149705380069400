@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.show-list {
  width: 100%;
  grid-column: 2 / 4;
  display: grid;
  grid-template-columns: repeat(auto-fit, 23.7rem);
  justify-content: start;
  gap: 1.2rem 1.6rem;

  @include media-medium {
    grid-row: 3 / 4;
  }

  @include media-laptop {
    grid-template-columns: repeat(auto-fit, 13.6rem);
    gap: 1.8rem;
    grid-column: 3 / 4;
  }

  @include media-small {
    padding: 27rem 1.6rem 1rem;
    justify-content: center;
    grid-column: 1 / 2;
  }

  @include media-mobile {
    padding-top: 25rem;
    gap: 1.6rem 1.4rem;
  }
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 0.6rem;
  grid-column: 2 / 4;

  @include media-medium {
    grid-row: 4 / 5;
  }

  @include media-laptop {
    grid-column: 3 / 4;
  }

  @include media-small {
    padding: 1rem 1.6rem 2rem;
    justify-content: center;
    grid-column: 1 / 2;
  }

  @include media-mobile {
    gap: 0.4rem;
  }
}

.pagination__button {
  @include button;
  @include text($color-bg);
  width: 3.2rem;
  height: 3.2rem;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30rem;
  border: 2px solid $color-bg;
  @extend %transition-all;

  @include media-hover {
    &:hover {
      color: $color-light-xl;
      background-color: $color-bg;
    }
  }

  &_active {
    color: $color-light-xl;
    background-color: $color-bg;
  }

  &_disable {
    @include disabled;
    opacity: 0.6;
  }

  &_type {
    &_prev {
      width: 5.2rem;
      background-image: url(../../../../images/icons/arrow-left-purple.svg);

      @include media-hover {
        &:hover {
          background-image: url(../../../../images/icons/arrow-left.svg);
        }
      }
    }

    &_next {
      width: 5.2rem;
      background-image: url(../../../../images/icons/arrow-right-purple.svg);

      @include media-hover {
        &:hover {
          background-image: url(../../../../images/icons/arrow-right.svg);
        }
      }
    }
  }
}

.pagination__dots {
  @include text($color-bg);
  width: 16px;

  &_prev {
    padding: 0 0 0 0.6rem;
  }

  &_next {
    padding: 0 0.6rem 0 0;
  }

  @include media-mobile {
    &_prev {
      padding: 0 0 0 0.4rem;
    }

    &_next {
      padding: 0 0.4rem 0 0;
    }
  }
}
