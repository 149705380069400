@import '../../../../styles/abstract/constants.scss';
@import '../../../../styles/abstract/mixins.scss';
@import '../../../../styles/abstract/placeholders.scss';

.image-slider {
  width: 44.1rem;
  height: 51.6rem;
  display: flex;
  gap: 1.6rem;
  grid-row: 1 / 3;
  grid-column: 1 / 2;

  .custom-arrow {
    display: none;

    @include media-tablet {
      display: flex;
      bottom: -4.8rem;
    }
  }

  @include media-tablet {
    width: 28.8rem;
    height: 37.2rem;
    grid-row: 1 / 2;
  }

  @include media-small {
    align-self: center;
  }
}

.image-slider__items {
  @include media-tablet {
    display: none;
  }
}

.thumbnail-slider {
  width: 9.7rem;

  .slick-list {
    height: 45.8rem !important;
    padding: 0 !important;
    margin: 3.2rem 0;
  }
}

.thumbnail-slider__item {
  margin-bottom: 1rem;
  padding: 0.4rem;
  height: 14.4rem;
  display: flex;
  align-items: center;
  border: 2px solid rgba(102, 0, 102, 0.4);
  border-radius: 1rem;
  cursor: pointer;

  &_active {
    border: 2px solid #660066;
  }
}

.thumbnail-slider__image {
  width: 100%;
  max-height: 13.6rem;
  object-fit: contain;
}

.image-slider__full {
  width: 100%;
  max-width: 32.9rem;
  position: relative;

  @include media-tablet {
    max-width: 28.8rem;
  }
}

.image-slider__image {
  cursor: pointer;

  img {
    width: 100%;
    max-width: 32.9rem;
    aspect-ratio: 1 / 1.57;
    object-fit: contain;
    object-position: top;

    @include media-tablet {
      max-width: 28.8rem;
      aspect-ratio: 1 / 1.11;
    }
  }
}

.image-slider__label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.3rem 2rem;
  border-radius: 2rem;
  @include text-l;
  background-color: $color-lime;
  z-index: 9;

  @include media-tablet {
    display: none;
  }
}
