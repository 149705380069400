@import '../../styles/abstract/placeholders.scss';
@import '../../styles/abstract/constants.scss';
@import '../../styles/abstract/mixins.scss';

.logo {
  @include link($color-light-xl);
  @extend %transition-all;

  @include media-hover {
    &:hover {
      transform: scale(1.03);
    }
  }
}

.logo__img {
  width: 22.6rem;
  height: 4.7rem;
  object-fit: contain;

  @include media-medium {
    width: 12rem;
    height: 2.4rem;
  }
}
