* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  @extend %font-prim;
  font-style: normal;
  color: $color-dark-xl;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  background-color: $color-light-l;

  main {
    width: 100%;
    flex: 1 0 auto;
  }

  footer {
    flex: 0 0 auto;
  }
}

.page {
  margin: 0;

  &_lock {
    overflow: hidden;

    @include media-hover {
      padding-right: 17px;
    }
  }
}

.wrapper {
  margin: auto;
  padding: 0 8rem;
  max-width: $content-width;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  @include media-medium {
    padding: 0 4rem;
  }

  @include media-tablet {
    padding: 0 2rem;
  }

  @include media-mobile {
    padding: 0 1.6rem;
  }
}

.background {
  margin: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  @extend %transition-all;
  z-index: 9;

  &_active {
    visibility: visible;
  }
}

.info-message {
  @include text-l;
  @extend %font-thin;
  overflow-wrap: break-word;
  hyphens: auto;
  grid-column: 1 / 4;

  @include media-medium {
    @include text-m;
  }
}
